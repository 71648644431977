<template>
  <div class="leaderboard container-fluid">
    <div class="text-center">
      <h1>{{ displayName }}</h1>
    </div>

    <div>
      <table class="table">
        <tbody>
          <tr v-for="player in players" :key="player.id">
            <td :class="getPlayerColor(player)">{{ player.position + 1 }}</td>
            <td :class="getPlayerColor(player)">{{ player.displayName }}</td>
            <td :class="getPlayerColor(player)">{{ player.statValue }}</td>
          </tr>

          <br>  
          <h4 v-if="checkActualPlayer && !isAnonymousPlayer" class="dot">{{ (players.length !== 0)  ? "..." : "" }}</h4>
          <tr v-if="checkActualPlayer && !isAnonymousPlayer">
            
            <td style="display: none"></td>
            <td class="custom-player-color">{{ actualPlayerComputed.position }}</td>
            <td class="custom-player-color">{{ actualPlayerComputed.displayName }}</td>
            <td class="custom-player-color">{{ actualPlayerComputed.statValue }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import { LEADERBOARDS_GET_ONE } from '@/store/modules/leaderboards.store'
export default {
  name: 'Leaderboard',
  props: {
    name: String,
    maxrows: Number,
    currentPlayer: Array,
  },
  data() {
    return {
      player: null,
      players: [],
      displayName: null,
    };
  },
  created() {
    this.players = [];
    this.displayName = "Leaderboard";
    this.fetchplayers();
    this.getPlayerColor(this.currentPlayer);
    this.getPlayer();
  },
  watch: {
    name (newValue, oldValue) {
      this.name = newValue;
      this.fetchplayers();
    },
    $route(to, from) {
      if (from != to) {
        this.name = to.params.name;
        this.maxrows = to.params.maxrows;
        this.created();
      }
    }
  },
  methods: {
    fetchplayers() {
      this.$store.dispatch(LEADERBOARDS_GET_ONE,this.name)
        .then(() => {
          this.players = this.$store.state.leaderboards.leaderboard.players.slice(0,this.maxrows);
          this.displayName = this.$store.state.leaderboards.leaderboard.displayName;
        });
    },
    getPlayerColor(player) {
      if(!this.currentPlayer){
        return "";
      }else {
        if(player.id === this.currentPlayer[0]?.id && this.currentPlayer[0]?.position <= this.maxrows){
          return "custom-player-color";
        }else{
          return "";
        }
      }
    },
    getPlayer() {
      // get the player that has been logged in in the $store from the last
      // player scan.
      this.player = JSON.parse(JSON.stringify(this.$store.state.players.player));
    },
    
  },
  computed: {
    actualPlayerComputed () {
      if(this.currentPlayer.length === 0){
        return {
          position: "--",
          displayName: this.player?.displayName?.toString() ?? "",
          statValue: "--"
        };
      }else{
        return {
          position: (this.currentPlayer[0]?.position +1 )?.toString() ?? "",
          displayName: this.currentPlayer[0]?.displayName?.toString() ?? "",
          statValue: this.currentPlayer[0]?.statValue?.toString() ?? ""
        }
      }
    },
    checkActualPlayer(){
      if(!this.currentPlayer){
        return true;
      }else if(this.currentPlayer[0]?.position <= this.maxrows){
        return false;
      }else{
        return true;
      }
    },
    isAnonymousPlayer() {
      if(this.player.customId == process.env.VUE_APP_ANONYMOUS_USER){
        return true;
      }
      else{
        return false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  text-transform: uppercase;
}
h3 {
  margin: 40px 0 0;
}
.custom-player-color{
  background-color: #2aa8b5 !important;
  color: white !important;
}
.dot{
    display: table-cell   
}
.table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}
.table tr td {
  font-size:x-large;
  background-color: #FEF2E9;
  color: #333;
  font-weight: bold;
}
.table tr:first-child td {
  font-weight: bold;
  font-size:xx-large;
  background-color: #EF791F;
}
.table tr:first-child td:first-child {
  color: transparent !important;
  font-size:xx-large;
}
.table tr:nth-child(2) td {
  font-weight: bold;
  background-color: #F5AC76;
  color: #111;
}
.table tr:nth-child(3) td {
  font-weight: bold;
  background-color: #F9C9A6;
  color: #222;
}
.table tr:first-child td:first-child::after {
  color: none;
  content: "🏆";
  color: white;
}
@media (max-width:320px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .table tr td {
    font-size: medium;
  }
  .table tr:first-child td {
    font-size:large;
  }
  .table tr:first-child td:first-child {
    font-size:xx-large;
  }
}
@media (max-width:481px)  { 
  .table tr td {
    font-size: medium;
  }
  .table tr:first-child td {
    font-size: large;
  }
  .table tr:first-child td:first-child {
    font-size: large;
  }
}
</style>