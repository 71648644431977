<template>
  <div class="main-panel leaderboard-view">
     <PageHeaderKiosk/>
     <LeaderboardPlayer :leaderboard="leaderboard"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeaderKiosk from '@/components/PageHeaderKiosk.vue'
import LeaderboardPlayer from '@/components/LeaderboardPlayer.vue'

export default {
  name: 'LeaderboardView',
  components: {
    PageHeaderKiosk,
    LeaderboardPlayer,								  
  },
  props: {
    leaderboard: Object,
  },
  watch: {
    $route(to, from) {
      if (from != to) {
        this.leaderboard = to.params.leaderboard;
      }
    }
  }
}
</script>
