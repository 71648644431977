<template>
  <div class='main-panel'>
    <b-container>
      <b-row>
        <b-col align-self="center">
          <h3>PLAYER LEADERBOARDS:</h3>
        </b-col>
      </b-row>
    </b-container>

    <div class="container-fluid leaderboard">
      <div class="row">
        <div class="col-12">
          <b-form-select
              v-model="selected"
              aria-describedby="input-live-help input-live-feedback"
              class="mb-2 mt-2 mr-2 ml2 custom-select"
              size="lg"
              @input="onChange">
                <option style="width: auto" v-for="option in options" :value="option" v-bind:key="option">
                  {{ option.displayName }}
                </option>
            </b-form-select>
        </div>
      </div>
    </div>

    <LeaderboardPlayersTable :name="selected._id" :maxrows="maxRows" :currentPlayer="actualPlayer"  />
    
  </div>
</template>

<script>
import { LEADERBOARDS_GET_ALL, LEADERBOARDS_GET_ONE } from '@/store/modules/leaderboards.store';
import LeaderboardPlayersTable from '@/components/LeaderboardPlayersTable.vue'
import { PLAYERS_GET_LEADERBOARD, PLAYERS_LOGIN_PLAYER } from '@/store/modules/players.store'

export default {
  name: 'LeaderboardPlayer',
  components: {
    LeaderboardPlayersTable,
  },
  props: {
    leaderboard: Object,
  },
  data() {
    return {
      player: null,
      actualPlayer: [],
      selected: null,
      id: null,
      options: [],
      playerId: '',
      isReversed: false,
    };
  },
  async created() {    
    this.getPlayer();
    if (!this.id) {
       await this.loginAnonymousPlayer();
       await this.getPlayer(); 
    }
    //Second time, if the anonymous user is not set then redirect to home.
    if (!this.id) {
      this.$router.push(`/home`)
    }
    this.options = JSON.parse(JSON.stringify(this.$store.state.leaderboards.leaderboards.filter((lb) => lb.isActive)));
    await  this.checkLeaderboardPropRoute();
    await this.getPlayerPosition();
  },
  watch: {
    $route(to, from) {
      if (from != to) {
        this.leaderboard = to.params.leaderboard;
        this.created();
      }
    }
  },
  computed:{
    isAnonymousPlayer() {
      if(this.player.customId == process.env.VUE_APP_ANONYMOUS_USER){
        return true;
      }
      else{
        return false;
      }
    },
    maxRows() {
      return this.isAnonymousPlayer ? 100 : 10;
    }
  },
  methods: {
    async onChange(event) {
      this.selected = event;
      this.getPlayer();
      this.getPlayerPosition();
    },
    async loginAnonymousPlayer() {   
      await this.$store.dispatch(PLAYERS_LOGIN_PLAYER, process.env.VUE_APP_ANONYMOUS_USER);
      
      await this.setPlayer(this.$store.state.players.player);
      await this.getLeaderboards();
     
    },
    async getLeaderboards() {
      await this.$store.dispatch(LEADERBOARDS_GET_ALL);
  },
    setPlayer(player) {
      this.player = JSON.parse(JSON.stringify(player));
      this.id = player._id;
      this.playerRfid = player.customId;
      this.displayName = player.displayName;
      this.fullName = player._id? 
        ((player.firstName? player.firstName : '-') + ' ' + 
        (player.lastName? player.lastName : '')).trim() 
        : null;
    },
    getPlayer() {
      // get the player that has been logged in in the $store from the last
      // player scan.
      this.player = JSON.parse(JSON.stringify(this.$store.state.players.player));
      this.id = this.player._id;
      
    },
    async getPlayerPosition(){
      await this.$store.dispatch(LEADERBOARDS_GET_ONE, this.selected._id);

      const playerId = this.player.playerId;
      const query = {
        id: playerId, 
        leaderboardName: this.$store.state.leaderboards.leaderboard.name
      }
      await this.$store.dispatch(PLAYERS_GET_LEADERBOARD,query)
        .then(() => { 
           const filterTopPlayer = JSON.parse(JSON.stringify(this.$store.state.leaderboards.leaderboard.players.filter(p => p.id === playerId)));
           const actualPlayerTemp = [{
            displayName: this.player.displayName,
            id: this.player.playerId,
            position: this.$store.state.players.leaderboard.position,
            statValue: this.$store.state.players.leaderboard.value
          }];

          if((actualPlayerTemp[0].position <= 10 && actualPlayerTemp[0].statValue === 0 ) || actualPlayerTemp[0].statValue === 0 ){
            if(filterTopPlayer.length === 0){
              this.actualPlayer=[];
            }else{
              this.actualPlayer=actualPlayerTemp;
            }
          }else{
            this.actualPlayer=actualPlayerTemp;
          }
      });    
    },
    checkLeaderboardPropRoute(){
      if(!this.leaderboard){
        this.selected =  this.options[0];
      } else{
        
        const leaderboardId = this.leaderboard._id;
        this.selected = this.options.filter(p => p._id === leaderboardId)[0];
      }
    }
  },
};
</script>

<style scoped>
select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: revert;
}
.input-rfid-box {
  display: flex;
  flex-direction: columns;
  gap: '8px';
  width: 100%;
}
.leaderboard {
  border-width: thin;
  border-color: #666;
}
.table tr td {
  font-size:x-large;
  color: #ffffff;
  background-color: #2acbcb;
}
.table tr:first-child td {
  font-weight: bold;
  font-size:xx-large;
}
.custom-select{
  background: #20419A;
  color: white;
}

</style>
